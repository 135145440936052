import React, { useState, useEffect, useRef } from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import NavBar from './NavBar';
import ASide from './ASide';
import Footer from './Footer';
import { useAuth } from './AuthContext';
import MUIDataTable from "mui-datatables";
import { CircularProgress, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { publish } from "../EventsAI";

export default function Dashboard() {
  const { checkAuthenticated, getToken } = useAuth();
  const [columns, setColumns] = useState([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState({name:"created_at",direction:"desc"});
  const [data, setData] = useState([[]]);
  const [backupSelected, setBackupSelected] = useState([]);

  const getData = async (page, sortOrder, search, filter) => {
    publish("loading",true);

    let finalData = [];
    let total = 0;

    try{
      let requestParams = {
        offset: page * rowsPerPage,
        limit: (page + 1) * rowsPerPage,
        search: search || "",
        filter: [],
        order: []
      };

      if(filter && filter.length > 0){
        filter.forEach((filterValues,index) => {
          if(filterValues && filterValues.length){
            requestParams.filter.push({name: columns[index].name, value: filterValues});
          }
        });
      }

      if(sortOrder.name){
        requestParams.order.push({name: sortOrder.name, dir: sortOrder.direction});
      }

      const response = await fetch(process.env.REACT_APP_API_HOST + "/chat/list", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': getToken(),
        },
        body: JSON.stringify(requestParams)
      });
      const result = await response.json();

      if(result && result.message && result.message == "success"){
        finalData = result.data || [];

        finalData.forEach((dataItem) => {
          try{
            dataItem.prompt = (new RegExp(".*Context:","m")).exec(dataItem.query.replace(/['\n']+/g, ''))[0].replace("Context:","").trim();
            dataItem.context = (new RegExp("(?:Context:)+.*Question:","m")).exec(dataItem.query.replace(/['\n']+/g, ''))[0].replace("Context:","").replace("Question:","").trim();
            dataItem.query = (new RegExp("(?:Question:)+.*","m")).exec(dataItem.query.replace(/['\n']+/g, ''))[0].replace("Question:","").trim();
          }catch(e){}
        });

        total = result.filtered;
      }
    }catch(e){
      console.log(e);
    }

    publish("loading",false);
    setData(finalData);
    setCount(total);
  };

  const deleteSelected = async () => {
    let idsToDelete = "";
    backupSelected.forEach((deleted) => {
      idsToDelete += deleted.id + ","
    });
    idsToDelete = idsToDelete.replace(/,\s*$/, "");
    if(idsToDelete){
      publish("loading",true);

      try{
        const response = await fetch(process.env.REACT_APP_API_HOST + "/chat/delete", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': getToken(),
          },
          body: JSON.stringify({id:idsToDelete})
        });
        const result = await response.json();

        if(result && result.message && result.message == "success"){
          publish("success");
          window.location.reload();
        }
      }catch(e){
        publish("error");
      }

      publish("loading",false);
    }
  };

  const options = {
    filter: true,
    filterType: 'dropdown',
    responsive: 'vertical',
    serverSide: true,
    count: count,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [],
    sortOrder: sortOrder,
    onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
      setBackupSelected([]);
      let backup = [];
      rowsSelected.forEach((selected) => {
        backup.push(Object.assign({},data[selected]));
      });
      setBackupSelected(backup);
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          getData(tableState.page, tableState.sortOrder, tableState.searchText, tableState.filterList);
          break;
        case 'sort':
          setSortOrder(tableState.sortOrder);
          getData(tableState.page, tableState.sortOrder, tableState.searchText, tableState.filterList);
          break;
        case 'search':
          getData(tableState.page, tableState.sortOrder, tableState.searchText, tableState.filterList);
          break;
        case 'filterChange':
          getData(tableState.page, tableState.sortOrder, tableState.searchText, tableState.filterList);
          break;
        default:
            console.log('action not handled.', action);
            console.log(tableState);
      }
    },
    onRowsDelete: (rowsDeleted, data) => {
      deleteSelected();
      setBackupSelected([]);
    },
    textLabels: {
      body: {
        noMatch: "¡Lo sentimos, no pudimos encontrar ningún registro!",
      },
      pagination:{
        next: "Página siguiente",
        previous: "Página anterior"
      },
      filter: {
        all: "Todos los registros",
        title: "Filtros Aplicados",
        reset: "Resetear Filtros",          
      },
      selectedRows: {
        text: "filas seleccionadas",
        delete: "Borrar fila",
        deleteAria: "Filas seleccionadas eliminadas"
      },
    }
  };

  useEffect(() => {
    checkAuthenticated();

    setColumns([
      {
        name: 'user_id',
        label: 'Usuario',
        options: {
         filter: true,
         sort: true
        }
      },
      {
        name: 'source',
        label: 'Origen',
        options: {
         filter: true,
         sort: true
        }
      },
      {
        name: 'company',
        label: 'Empresa',
        options: {
         filter: true,
         sort: true
        }
      },
      {
        name: 'prompt',
        label: 'PROMPT',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            if(value){
              let resumme = value.length >= 50 ? value.substr(0,47) + "..." : "";

              if(resumme){
                return (
                  <Tooltip title={value}>
                    {resumme}
                  </Tooltip>
                );
              }else{
                return value;
              }
            }
          }
        },
      },
      {
        name: 'context',
        label: 'CONTEXTO',
        options: {
         filter: false,
         sort: false,
         customBodyRender: (value, tableMeta, updateValue) => {
            if(value){
              let resumme = value.length >= 50 ? value.substr(0,47) + "..." : "";

              if(resumme){
                return (
                  <Tooltip title={value}>
                    {resumme}
                  </Tooltip>
                );
              }else{
                return value;
              }
            }
          }
        },
      },
      {
        name: 'query',
        label: 'Pregunta',
        options: {
         filter: true,
         customBodyRender: (value, tableMeta, updateValue) => {
            if(value){
              let resumme = value.length >= 50 ? value.substr(0,47) + "..." : "";

              if(resumme){
                return (
                  <Tooltip title={value}>
                    {resumme}
                  </Tooltip>
                );
              }else{
                return value;
              }
            }
          }
        }
      },
      {
        name: 'answer',
        label: 'Respuesta',
        options: {
         filter: true,
         customBodyRender: (value, tableMeta, updateValue) => {
            if(value){
              let resumme = value.length >= 50 ? value.substr(0,47) + "..." : "";

              if(resumme){
                return (
                  <Tooltip title={value}>
                    {resumme}
                  </Tooltip>
                );
              }else{
                return value;
              }
            }
          }
        }
      },
      {
        name: 'tokens',
        label: 'Conteo',
        options: {
         sort: true
        }
      },
      {
        name: 'cost',
        label: 'Coste',
        options: {
         sort: true
        }
      },
      {
        name: 'created_at',
        label: 'Fecha',
        options: {
         filter: true,
         sort: true
        }
      }
    ]);

    getData(0, sortOrder);
  }, []);

  return (
    <div>
      <NavBar title="Historial" />
      <Box sx={{ display: 'flex', flexDirection: {xs:'column', md: 'row'} }}>
        <ASide />
        <Box sx={{ width: '100%' }}>
          <main>
            <Container component="main">
              <MUIDataTable
                data={data}
                columns={columns}
                options={options}
              />
            </Container>
          </main>
        </Box>
      </Box>
      <Footer />
    </div>
  );
}