import React, { useState, useEffect } from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import NavBar from './NavBar';
import ASide from './ASide';
import Footer from './Footer';
import { useAuth } from './AuthContext';
import Snackbar from '@mui/material/Snackbar';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { styled } from '@mui/system';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Autocomplete from "@mui/material/Autocomplete";
import { publish } from "../EventsAI";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import Tooltip from '@mui/material/Tooltip';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import CommentIcon from '@mui/icons-material/Comment';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import CreateTwoToneIcon from '@mui/icons-material/CreateTwoTone';
import ListItemIcon from '@mui/material/ListItemIcon';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import SearchIcon from '@mui/icons-material/Search';
import JoditEditor from 'jodit-react';
import parse from 'html-react-parser';
import { v4 as uuidv4 } from 'uuid';
import { useDebouncedCallback } from 'use-debounce';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function CreateUser() {
  const [contextFile, setContextFile] = useState({});
  const [topics, setTopics] = useState([]);
  const { checkAuthenticated, getToken } = useAuth();
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [search, setSearch] = useState(false);
  const [type, setType] = useState("");
  const [editChild, setEditChild] = useState(false);
  const [currentEdit, setCurrentEdit] = useState({});
  const [currentEditChild, setCurrentEditChild] = useState({});
  const [editBuffer, setEditBuffer] = useState({});
  const [editBufferChild, setEditBufferChild] = useState({});
  const [recordId, setRecordId] = useState(-1);
  const [titles, setTitles] = useState({
    title: "Nuevo Usuario",
    button: "Crear Usuario"
  });
  const [fromManual, setFromManual] = useState(false);
  const [currentParent, setCurrentParent] = useState(0);
  const [currentParentUuid, setCurrentParentUuid] = useState("");
  const [topic, setTopic] = useState("");
  const [query, setQuery] = useState("");
  const [queryResults, setQueryResults] = useState([]);
  const [deleteds, setDeleteds] = useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (response) => {
    setOpen(false);

    if(response){
      window.location.replace(process.env.REACT_APP_URL + "/users")
    }
  };

  const [formValues, setFormValues] = useState({
    name:{
      value:'',
      error:false,
      errorMessage:'El campo es obligatorio'
    },
    email:{
      value:'',
      error:false,
      errorMessage:'El campo es obligatorio'
    },
    password:{
      value:'',
      error:false,
      errorMessage:'El campo es obligatorio'
    },
    newpassword:{
      value:'',
      error:false,
      errorMessage:'El campo es obligatorio'
    },
    is_admin:{
      value:false,
      error:false,
      errorMessage:''
    },
    remember:{
      value:false,
      error:false,
      errorMessage:''
    },
  });

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const handleFormChange = (e) => {
    const {name, value} = e.target;

    let newValues = JSON.parse(JSON.stringify(formValues));
    newValues[name].value = value;
    setFormValues(newValues);
    console.log(newValues);
  }

  const handleAdminChange = (e) => {
    const {name, value} = e.target;

    let newValues = JSON.parse(JSON.stringify(formValues));
    newValues["is_admin"].value = e.target.checked;
    setFormValues(newValues);
  }

  const handleRememberChange = (e) => {
    const {name, value} = e.target;

    let newValues = JSON.parse(JSON.stringify(formValues));
    newValues["remember"].value = e.target.checked;
    setFormValues(newValues);
  }

  useEffect(() => {
    checkAuthenticated();

    const searchParams = new URLSearchParams(window.location.search);
    if(searchParams && searchParams.has('id') && searchParams.get('id')){
      fetchContext(searchParams.get('id'));
      setTitles({
        title: "Editar Usuario",
        button: "Editar Usuario"
      });
    }
  }, []);

  async function fetchContext(id) {
    setRecordId(id);

    publish("loading", true);

    try{
      const response = await fetch(process.env.REACT_APP_API_HOST + "/user/" + id, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': getToken(),
        }
      });
      const data = await response.json();

      if(data && data.message && data.message == "success"){
        try{
          let newValues = JSON.parse(JSON.stringify(formValues));
          let editProps = ["name","email","password","is_admin","remember"];
          editProps.forEach((prop) => {
            let finalValue = data.data[prop];
            if(prop == "is_admin") finalValue = finalValue ? true : false;
            if(prop == "remember") finalValue = finalValue ? true : false;
            newValues[prop].value = finalValue;
          });
          newValues["password"] = "";
          newValues["newpassword"] = "";
          setFormValues(newValues);
        }catch(e){
          console.log(e);
        }
      }else{
        publish("error");
      }
    }catch(e){
      publish("error",e.message);
      console.log(e);
    }

    publish("loading", false);
  }

  const handleSubmit = async (event) => {
    publish("loading", true);

    try{
      event.preventDefault();

      let errors = false;
      const formFields = Object.keys(formValues);
      let newFormValues = {...formValues}

      for (let index = 0; index < formFields.length; index++) {
        const currentField = formFields[index];
        const currentValue = formValues[currentField].value;

        if(formFields[index] == 'name' || formFields[index] == 'email' || formFields[index] == 'password' || (recordId && formFields[index] == 'newpassword')){
          if(!currentValue){
            errors = true;

            newFormValues = {
              ...newFormValues,
              [currentField]:{
                ...newFormValues[currentField],
                error:true
              }
            }

          }
        }
      }

      setFormValues(newFormValues);
      if(errors){
        publish("loading", false);
        return;
      }

      const response = await fetch(process.env.REACT_APP_API_HOST + "/user/register", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': getToken(),
        },
        body: JSON.stringify({
          name: formValues.name.value + "",
          email: formValues.email.value + "",
          password: formValues.password.value + "",
          is_admin: formValues.is_admin.value + "",
          remember: formValues.remember.value + "",
          id: recordId + ""
        })
      });
      const data = await response.json();

      if(data && data.email){
        publish("success");
        //setOpen(true);
        handleClose(true);
      }else{
        publish("error");
      }
    }catch(e){
      publish("error",e.message);
      console.log(e);
    }

    publish("loading", false);
  };

  return (
    <div>
      <NavBar title={titles.title} />
      <Box sx={{ display: 'flex', flexDirection: {xs:'column', md: 'row'} }}>
        <ASide />
        <Box sx={{ width: {xs:'80%', md: '100%'} }}>
          <Container component="main">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="name"
                  label="Nombre"
                  name="name"
                  value={formValues.name.value}
                  error={formValues.name.error}
                  helperText={formValues.name.error && formValues.name.errorMessage}
                  onChange={handleFormChange}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="E-mail"
                  name="email"
                  value={formValues.email.value}
                  error={formValues.email.error}
                  helperText={formValues.email.error && formValues.email.errorMessage}
                  onChange={handleFormChange}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="password"
                  label="Contraseña"
                  name="password"
                  type="password"
                  value={formValues.password.value}
                  error={formValues.password.error}
                  helperText={formValues.password.error && formValues.password.errorMessage}
                  onChange={handleFormChange}
                />
                {recordId ? 
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="newpassword"
                  label="Nueva Contraseña"
                  name="newpassword"
                  type="password"
                  value={formValues.newpassword.value}
                  error={formValues.newpassword.error}
                  helperText={formValues.newpassword.error && formValues.newpassword.errorMessage}
                  onChange={handleFormChange}
                />
                :
                (null)
                }
                <FormControl fullWidth>
                  <InputLabel id="public_label">Administrador</InputLabel>
                  <Checkbox
                    margin="normal"
                    onChange={handleAdminChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                    checked={formValues.is_admin.value}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="remember_label">Recordar</InputLabel>
                  <Checkbox
                    margin="normal"
                    onChange={handleRememberChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                    checked={formValues.remember.value}
                  />
                </FormControl>
                <div style={{margin:"2% 0 2% 0"}}></div>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    {titles.button}
                </Button>
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>
      <Footer />
    </div>
  );
}