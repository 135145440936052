import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import '../styles/Footer.css';
import { subscribe, unsubscribe } from "../EventsAI";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { Close } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import SvgIcon from '@mui/material/SvgIcon';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://oposicionlocal.es">
        Oposicionlocal
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const Footer = (props) => {
  return (
    <footer>
    	<Copyright sx={{ mt: 8, mb: 4 }} />
    </footer>
  );
};
export default Footer;