import React, { useState, useEffect, useRef } from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import NavBar from './NavBar';
import ASide from './ASide';
import Footer from './Footer';
import { useAuth } from './AuthContext';
import MUIDataTable from "mui-datatables";
import { CircularProgress, Typography } from '@mui/material';
import { publish } from "../EventsAI";
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import CreateTwoToneIcon from '@mui/icons-material/CreateTwoTone';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import MicrosoftIcon from '@mui/icons-material/Microsoft';

export default function Dashboard() {
  const { checkAuthenticated, getToken } = useAuth();
  const [columns, setColumns] = useState([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState({});
  const [data, setData] = useState([[]]);
  const [backupSelected, setBackupSelected] = useState([]);

  const getData = async (page, sortOrder, search, filter) => {
    publish("loading",true);

    let finalData = [];
    let total = 0;

    try{
      const response = await fetch(process.env.REACT_APP_API_HOST + "/topics/list", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': getToken(),
        },
        body: JSON.stringify({
          search: search
        })
      });
      const result = await response.json();

      if(result && result.message && result.message == "success"){
        finalData = result.data || [];

        if(search){
          finalData = finalData.filter((item) => item.name.indexOf(search) >= 0 || item.description.indexOf(search) >= 0);
        }

        if(filter && filter.length > 0){
          filter.forEach((filterValues,index) => {
            if(filterValues && filterValues.length){
              finalData = finalData.filter((item) => filterValues.includes(item[columns[index].name]));
            }
          });
        }

        total = finalData.length;

        let sortField = sortOrder.name;
        let sortDir = sortOrder.direction;

        if (sortField) {
          finalData = finalData.sort((a, b) => {
            if (a[sortField] < b[sortField]) {
              return 1 * (sortDir === 'asc' ? -1 : 1);
            } else if (a[sortField] > b[sortField]) {
              return -1 * (sortDir === 'asc' ? -1 : 1);
            } else {
              return 0;
            }
          });
        }

        finalData = finalData.slice(page * rowsPerPage, (page + 1) * rowsPerPage);

        finalData = finalData.map((item) => {
          let decorate = Object.assign({},item);
          decorate.processing = decorate.processing ? "Sí" : "No";
          return decorate;
        });

      }
    }catch(e){
      console.log(e);
    }

    publish("loading",false);
    setData(finalData);
    setCount(total);
  };

  const deleteSelected = async (id) => {
    let idsToDelete = "";
    backupSelected.forEach((deleted) => {
      idsToDelete += deleted.id + ","
    });
    idsToDelete = idsToDelete.replace(/,\s*$/, "");

    if(id){
      idsToDelete = id + ""
    }

    if(idsToDelete){
      publish("loading",true);

      try{
        const response = await fetch(process.env.REACT_APP_API_HOST + "/topics/delete", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': getToken(),
          },
          body: JSON.stringify({id:idsToDelete})
        });
        const result = await response.json();

        if(result && result.message && result.message == "success"){
          publish("success");
          window.location.reload();
        }
      }catch(e){
        publish("error");
      }

      publish("loading",false);
    }
  };

  const options = {
    filter: false,
    filterType: 'dropdown',
    responsive: 'vertical',
    serverSide: true,
    count: count,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [],
    sortOrder: sortOrder,
    onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
      setBackupSelected([]);
      let backup = [];
      rowsSelected.forEach((selected) => {
        backup.push(Object.assign({},data[selected]));
      });
      setBackupSelected(backup);
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          getData(tableState.page, tableState.sortOrder, tableState.searchText, tableState.filterList);
          break;
        case 'sort':
          getData(tableState.page, tableState.sortOrder, tableState.searchText, tableState.filterList);
          break;
        case 'search':
          getData(tableState.page, tableState.sortOrder, tableState.searchText, tableState.filterList);
          break;
        case 'filterChange':
          getData(tableState.page, tableState.sortOrder, tableState.searchText, tableState.filterList);
          break;
        default:
            console.log('action not handled.', action);
            console.log(tableState);
      }
    },
    onRowsDelete: (rowsDeleted, data) => {
      deleteSelected();
      setBackupSelected([]);
    },
    textLabels: {
      body: {
        noMatch: "¡Lo sentimos, no pudimos encontrar ningún registro!",
      },
      pagination:{
        next: "Página siguiente",
        previous: "Página anterior"
      },
      filter: {
        all: "Todos los registros",
        title: "Filtros Aplicados",
        reset: "Resetear Filtros",          
      },
      selectedRows: {
        text: "filas seleccionadas",
        delete: "Borrar fila",
        deleteAria: "Filas seleccionadas eliminadas"
      },
    }
  };

  useEffect(() => {
    checkAuthenticated();

    setColumns([
      {
        name: 'name',
        label: 'Nombre',
        options: {},
      },
      {
        name: 'description',
        label: 'Descripción',
        options: {},
      },
      {
        name: 'uuid',
        label: 'Fichero',
        options: {},
      },
      {
        name: 'actions',
        label: 'Acciones',
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
                <ListItem button onClick={function(e){
                  e.preventDefault();
                  window.location.replace(process.env.REACT_APP_URL + "/createcontext?id=" + this.item)
                }.bind({item: tableMeta.tableData[tableMeta.rowIndex].id})}>
                  <ListItemIcon>
                    <CreateTwoToneIcon />
                  </ListItemIcon>
                </ListItem>
                <ListItem button onClick={function(e){
                  e.preventDefault();
                  deleteSelected(this.item);
                }.bind({item: tableMeta.tableData[tableMeta.rowIndex].id})}>
                  <ListItemIcon>
                    <DeleteTwoToneIcon />
                  </ListItemIcon>
                </ListItem>
                <ListItem button onClick={function(e){
                  e.preventDefault();
                  try{
                    publish("loading", true);

                    fetch(process.env.REACT_APP_API_HOST + "/topics/export/" + this.item + "/pdf", {
                      method: 'GET',
                      headers: {
                        'Authorization': getToken(),
                      }
                    }).then(resp => resp.blob())
                    .then(blob => {
                      const url = window.URL.createObjectURL(blob);
                      const a = document.createElement('a');
                      a.style.display = 'none';
                      a.href = url;
                      a.download = 'export.pdf';
                      document.body.appendChild(a);
                      a.click();
                      window.URL.revokeObjectURL(url);

                      publish("loading", false);
                    })
                    .catch((e) => {
                      publish("error",e.message || e);
                      console.log(e);
                      publish("loading", false);
                    });
                  }catch(e){
                    publish("error",e.message || e);
                    console.log(e);
                    publish("loading", false);
                  }
                }.bind({item: tableMeta.tableData[tableMeta.rowIndex].id})}>
                  <ListItemIcon>
                    <PictureAsPdfIcon />
                  </ListItemIcon>
                </ListItem>
                <ListItem button onClick={function(e){
                  e.preventDefault();
                  try{
                    publish("loading", true);

                    fetch(process.env.REACT_APP_API_HOST + "/topics/export/" + this.item + "/docx", {
                      method: 'GET',
                      headers: {
                        'Authorization': getToken(),
                      }
                    }).then(resp => resp.blob())
                    .then(blob => {
                      const url = window.URL.createObjectURL(blob);
                      const a = document.createElement('a');
                      a.style.display = 'none';
                      a.href = url;
                      a.download = 'export.docx';
                      document.body.appendChild(a);
                      a.click();
                      window.URL.revokeObjectURL(url);

                      publish("loading", false);
                    })
                    .catch((e) => {
                      publish("error",e.message || e);
                      console.log(e);
                      publish("loading", false);
                    });
                  }catch(e){
                    publish("error",e.message || e);
                    console.log(e);
                    publish("loading", false);
                  }
                }.bind({item: tableMeta.tableData[tableMeta.rowIndex].id})}>
                  <ListItemIcon>
                    <MicrosoftIcon />
                  </ListItemIcon>
                </ListItem>
              </>
            );
          }
        },
      },
    ]);

    getData(0, {});
  }, []);

  return (
    <div>
      <NavBar title="Temarios" />
      <Box sx={{ display: 'flex', flexDirection: {xs:'column', md: 'row'} }}>
        <ASide />
        <Box sx={{ width: '100%' }}>
          <main>
            <Container component="main">
              <MUIDataTable
                data={data}
                columns={columns}
                options={options}
              />
            </Container>
          </main>
        </Box>
      </Box>
      <Footer />
    </div>
  );
}