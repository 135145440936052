import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import '../styles/ASide.css';
import { subscribe, unsubscribe } from "../EventsAI";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { Close } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import SvgIcon from '@mui/material/SvgIcon';
import Logo from "../assets/cobertec.png";
import Divider from '@mui/material/Divider';
import MarkChatReadTwoToneIcon from '@mui/icons-material/MarkChatReadTwoTone';
import ChatTwoToneIcon from '@mui/icons-material/ChatTwoTone';
import HistoryTwoToneIcon from '@mui/icons-material/HistoryTwoTone';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Container from '@mui/material/Container';
import GroupSharpIcon from '@mui/icons-material/GroupSharp';
import GroupAddSharpIcon from '@mui/icons-material/GroupAddSharp';

const ASide = (props) => {
	const { classes } = props;

	return (
		<Box sx={{ width: {xs:'100%', md: '20%'} }}>
			<Container component="aside">
				<List component="nav">
			    <ListItem button onClick={() => window.location.replace(process.env.REACT_APP_URL + "/dashboard") }>
			      <ListItemIcon>
			        <MarkChatReadTwoToneIcon />
			      </ListItemIcon>
			      <ListItemText primary="Temarios" />
			    </ListItem>
			    <ListItem button onClick={() => window.location.replace(process.env.REACT_APP_URL + "/createcontext")}>
			      <ListItemIcon>
			        <ChatTwoToneIcon />
			      </ListItemIcon>
			      <ListItemText primary="Nuevo Temario" />
			    </ListItem>
				<ListItem button onClick={() => window.location.replace(process.env.REACT_APP_URL + "/users")}>
			      <ListItemIcon>
			        <GroupSharpIcon />
			      </ListItemIcon>
			      <ListItemText primary="Usuarios" />
			    </ListItem>
				<ListItem button onClick={() => window.location.replace(process.env.REACT_APP_URL + "/createuser")}>
			      <ListItemIcon>
			        <GroupAddSharpIcon />
			      </ListItemIcon>
			      <ListItemText primary="Nuevo Usuario" />
			    </ListItem>
			  </List>
			</Container>
	 	</Box>
	);
};

export default ASide;