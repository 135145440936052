import React from 'react';
import ReactWebComponent from 'react-web-component';
import App from "./App";
import Login from './admin/Login';
import ResetPassword from './admin/ResetPassword';
import Dashboard from './admin/Dashboard';
import ChatList from './admin/ChatList';
import CreateContext from './admin/CreateContext';
import Users from './admin/Users';
import CreateUser from './admin/CreateUser';
import { AuthProvider } from './admin/AuthContext';
import './styles/index.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
import { withRouter } from './withRouter';

const AppRoutes = () => (
	<Routes>
		<Route exact path="/" element={<App />}/>
		<Route exact path="/login" element={<Login />}/>
		<Route exact path="/dashboard" element={<Dashboard />}/>
		<Route exact path="/createcontext" element={<CreateContext />}/>
		<Route exact path="/users" element={<Users />}/>
		<Route exact path="/createuser" element={<CreateUser />}/>
		<Route exact path="/resetpassword" element={<ResetPassword />}/>
		<Route exact path="/chatlist" element={<ChatList />}/>
    <Route path="*" element={<App />} />
	</Routes>
);

const AppWrapper = withRouter(AppRoutes);

const RoutedApp = () => (
  <Router>
  	<AuthProvider>
		<AppWrapper />
	</AuthProvider>
  </Router>
);

ReactWebComponent.create(<RoutedApp />, 'chat-ai', false);