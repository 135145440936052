import React, { useState, Image, useEffect } from 'react';
import './styles/App.css';
import Dashboard from './admin/Dashboard';

/* App.js*/

function App({ configuration }) {
	return <Dashboard />;
}

export default App;