import React, { useState, useEffect } from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import NavBar from './NavBar';
import ASide from './ASide';
import Footer from './Footer';
import Snackbar from '@mui/material/Snackbar';
import { redirect } from "react-router-dom";
import { useAuth } from './AuthContext';

export default function Login() {
  const [loginError, setLoginError] = useState(false);
  const { isAuthenticated, login, logout, checkAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      window.location.replace(process.env.REACT_APP_URL + "/dashboard");
    }
  }, [isAuthenticated]);

  const handleSubmit = async (event) => {
    try{
      event.preventDefault();
      const form = new FormData(event.currentTarget);
      const response = await fetch(process.env.REACT_APP_API_HOST + "/user/login", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({email: form.get('email'), password: form.get('password'), remember: form.get('remember')})
      });
      const data = await response.json();

      if(data && data.message && data.message == "success" && data.expiresAt){
        localStorage.setItem("CobertecIA", JSON.stringify({expiresAt: data.expiresAt, token: data.token}));
        setLoginError(false);
        login();
        window.location.replace(process.env.REACT_APP_URL + "/dashboard");
      }else{
        logout();
        setLoginError(true);
      }
    }catch(e){
      logout();
      console.log(e);
      setLoginError(true);
    }
  };

  return (
    <div>
      <NavBar />
      <main>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Usuario"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Contraseña"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Recordar"
                name="remember"
                id="remember"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Acceder
              </Button>
              {<Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2" onClick={() => window.location.replace(process.env.REACT_APP_URL + "/resetpassword")}>
                    Olvido la contraseña?
                  </Link>
                </Grid>
              </Grid>}
            </Box>
            <Snackbar
              open={loginError}
              autoHideDuration={2000}
              message="Error al iniciar sesión."
            />
          </Box>
        </Container>
      </main>
      <Footer />
    </div>
  );
}