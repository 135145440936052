import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import axios from 'axios';
import '../styles/NavBar.css';
import { subscribe, unsubscribe } from "../EventsAI";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { Close } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import SvgIcon from '@mui/material/SvgIcon';
import Logo from "../assets/cronotemas.png";
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import LinearProgress from '@mui/material/LinearProgress';
import LogoutTwoToneIcon from '@mui/icons-material/LogoutTwoTone';
import { useAuth } from './AuthContext';
import Snackbar from '@mui/material/Snackbar';

const NavBar = (props) => {
	const div = useRef();
	const [isLoading, setIsLoading] = useState(false);
	const [actionError, setActionError] = useState(false);
	const [actionErrorMsg, setActionErrorMsg] = useState(false);
	const [actionSuccess, setActionSuccess] = useState(false);
	const [actionSuccessMsg, setActionSuccessMsg] = useState(false);
	const [offsetY, setOffsetY] = useState(0);
	const { getToken } = useAuth();

	useEffect(() => {
	    subscribe("loading", (event) => setIsLoading(event.detail));
	    subscribe("error", (event) => {setActionError(true);setActionErrorMsg(event.detail || "Error en la acción.")});
	    subscribe("success", (event) => {setActionSuccess(true);setActionSuccessMsg(event.detail || "Acción realizada con éxito.")});

	    return () => {
	     	subscribe("loading", (event) => setIsLoading(event.detail));
	     	subscribe("error", (event) => {setActionError(true);setActionErrorMsg(event.detail || "Error en la acción.")});
	     	subscribe("success", (event) => {setActionSuccess(true);setActionSuccessMsg(event.detail || "Acción realizada con éxito.")});
	    }
	}, []);

	useLayoutEffect(() => {
	    const onScroll = () => {
	    	setOffsetY(window.pageYOffset);

	   		if(isLoading){
	   			div.current.style.display = "";
	   		}else{
	   			div.current.style.display = "hidden";
	   		}
	    };
	    window.addEventListener("scroll", onScroll);
	    return () => window.removeEventListener("scroll", onScroll);
	}, []);

	return (
	    <header>
	      <Box ref={div} sx={{ display: isLoading ? "" : "hidden", position: "absolute", transform: `translateY(${offsetY + "px"})`, width: "100%"}}>
	      	<LinearProgress />
	      </Box>
	      <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
	      	<div><img src={Logo} style={{height:"55px"}} /></div>
	      	<div className="App-custom-link" style={{
			    display: 'flex',
			    alignItems: 'center',
			    flexWrap: 'wrap',
			    marginRight: '2%'
			}}
				onClick={async () => {
					await fetch(process.env.REACT_APP_API_HOST + "/user/logout", {
				        method: 'POST',
				        headers: {
				          'Content-Type': 'application/json',
				          'Authorization': getToken(),
				        }
				    });
				    localStorage.removeItem("CobertecIA");
				    window.location.reload();
				}}
			>
			    <span style={{color:'#007bff'}}>SALIR</span>
			    <LogoutTwoToneIcon />
			</div>  
	      </div>
	      <div className="navbar-options">
	      	<div></div>
	      	<div><h2 className="App-title">{props.title}</h2></div>
	      	<div></div>
	      </div>
	      <Snackbar
              open={actionError}
              autoHideDuration={2000}
              message={actionErrorMsg}
              onClose={() => { setActionError(false) }}
          />
          <Snackbar
              open={actionSuccess}
              autoHideDuration={2000}
              message={actionSuccessMsg}
              onClose={() => { setActionSuccess(false) }}
          />
	    </header>
	);
};
export default NavBar;