import React, { useState, useEffect } from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import NavBar from './NavBar';
import ASide from './ASide';
import Footer from './Footer';
import Snackbar from '@mui/material/Snackbar';
import { redirect } from "react-router-dom";
import { useAuth } from './AuthContext';
import { publish } from "../EventsAI";

export default function ResetPassword() {
  const [formError, setFormError] = useState(false);

  const [formValues, setFormValues] = useState({
    email:{
      value:'',
      error:false,
      errorMessage:'El campo es obligatorio'
    },
    password:{
      value:'',
      error:false,
      errorMessage:'El campo es obligatorio'
    }
  });

  const handleFormChange = (e) => {
    const {name, value} = e.target;

    let newValues = JSON.parse(JSON.stringify(formValues));
    newValues[name].value = value;
    setFormValues(newValues);
  }

  const handleSubmit = async (event) => {
    publish("loading");

    try{
      event.preventDefault();

      let errors = false;
      const formFields = Object.keys(formValues);
      let newFormValues = {...formValues}

      for (let index = 0; index < formFields.length; index++) {
        const currentField = formFields[index];
        const currentValue = formValues[currentField].value;

        if(formFields[index] == 'email' || formFields[index] == 'password'){
          if(currentValue === ''){
            errors = true;

            newFormValues = {
              ...newFormValues,
              [currentField]:{
                ...newFormValues[currentField],
                error:true
              }
            }

          }
        }
      }

      setFormValues(newFormValues);
      if(errors){
        publish("loading");
        return;
      }

      const form = new FormData(event.currentTarget);
      const response = await fetch(process.env.REACT_APP_API_HOST + "/user/passwordreset", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({email: form.get('email'), password: form.get('password')})
      });
      const data = await response.json();

      if(data && data.message && data.message == "success"){
        setFormError(false);
        window.location.replace(process.env.REACT_APP_URL + "/login");
      }else{
        setFormError(true);
      }
    }catch(e){
      console.log(e);
      setFormError(true);
    }

    publish("loading");
  };

  return (
    <div>
      <NavBar />
      <main>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="E-mail"
                name="email"
                value={formValues.email.value}
                error={formValues.email.error}
                helperText={formValues.email.error && formValues.email.errorMessage}
                onChange={handleFormChange}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                value={formValues.password.value}
                error={formValues.password.error}
                label="Contraseña"
                type="password"
                id="password"
                helperText={formValues.password.error && formValues.password.errorMessage}
                onChange={handleFormChange}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Modificar Contraseña
              </Button>
              <div>* Se enviará un enlace de modificación de contraseña al e-mail indicado.</div>
            </Box>
            <Snackbar
              open={formError}
              autoHideDuration={2000}
              message="Error en el proceso."
            />
          </Box>
        </Container>
      </main>
      <Footer />
    </div>
  );
}