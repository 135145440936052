import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const login = () => {
    setIsAuthenticated(true);
  };

  const logout = () => {
    setIsAuthenticated(false);
  };

  const getToken = () => {
    return JSON.parse(localStorage.getItem("CobertecIA")).token;
  };

  const checkAuthenticated = () => {
    try{
      if(!localStorage.getItem("CobertecIA")){
        logout();
        window.location.replace(process.env.REACT_APP_URL + "/login");
      }else{
        let authData = JSON.parse(localStorage.getItem("CobertecIA"));
        if(authData && authData.expiresAt && (new Date()).valueOf() <= (new Date(authData.expiresAt * 1000)).valueOf()){
          login();
        }else{
          logout();
          window.location.replace(process.env.REACT_APP_URL + "/login");
        }
      }
    }catch(e){
      logout();
      window.location.replace(process.env.REACT_APP_URL + "/login");
    }
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, checkAuthenticated, getToken }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};